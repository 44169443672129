import configureStore from '@/shared/state/configure-store';
import {
  handleUnauthorizedAccess,
  handleIncompleteProfile,
} from '@/shared/state/profile/actions';
import { DataPipelinesConnectionTypesEnum } from '@/shared/constants/dataPipelines';
import {
  API_BASE_URL,
  API_PATH_DATA_PIPELINE_CONNECTION_TEST,
  API_PATH_DATA_PIPELINE_CONNECTION_TEST_API,
  API_PATH_DATA_PIPELINE_CONNECTION_TEST_JDBC,
  API_PATH_DATA_PIPELINE_FORMS,
  API_PATH_DATABASE_DETAILS,
} from '@/shared/constants/api';
import {
  DataPipelinesSectionEnum,
  SECTION_CONNECTION_TEST_API,
  SECTION_CONNECTION_TEST_JDBC,
  SECTION_CONNECTION_TEST_SERVICE,
} from '@/shared/constants/dataPipelines/sections';
import { DHIS2FormValues } from '@/shared/interfaces/form';
import {
  payloadTransformConnectionTestAPI,
  payloadTransformConnectionTestAPISource,
  payloadTransformConnectionTestJDBC,
} from '@/pages/data-pipeline/components/forms/schemas/transforms/connectionTest';
import { ClientDomainEnum, UserInfo } from '@/shared/interfaces/user';
import { getManagerInfo } from '@/shared/services/manager';
import { push } from 'connected-react-router';
import { ROUTES } from '@/shared/constants/navigation';
import { toast } from 'react-toastify';
import { getError } from '.';
import ToastIcon, {
  toastTypes,
} from '../components/Layout/components/ToastIcon';
import {
  SchemaCodeEditor,
  SchemaTableInfo,
} from '../interfaces/api/SchemaPayload';

const { dispatch } = configureStore().store();

export const XHR = (
  url: string,
  init: {
    method:
      | 'post'
      | 'POST'
      | 'get'
      | 'GET'
      | 'head'
      | 'HEAD'
      | 'delete'
      | 'DELETE'
      | 'connect'
      | 'CONNECT'
      | 'options'
      | 'OPTIONS'
      | 'trace'
      | 'TRACE'
      | 'patch'
      | 'PATCH';
    body: any;
    headers?: { [header: string]: string };
    credentials?: string | boolean;
    timeout?: number;
  }
) => {
  const { method = 'get', body, headers, credentials, timeout } = init;
  const request = new XMLHttpRequest();
  request.open(method, url);
  headers &&
    Object.keys(headers).forEach((key) => {
      request.setRequestHeader(key, headers[key]);
    });
  if (credentials) {
    request.withCredentials = true;
  }
  if (typeof timeout === 'number') {
    request.timeout = timeout;
  }
  request.send(body || ' ');
  return new Promise((resolve, reject) => {
    request.addEventListener('load', (progress) => {
      const result = { xhr: request, progress } as {
        xhr: XMLHttpRequest;
        progress: ProgressEvent<EventTarget>;
      };
      resolve(result.xhr);
    });
    request.addEventListener('error', () => reject(request));
    request.addEventListener('timeout', () => reject(request));
    request.addEventListener('abort', () => reject(request));
  });
};

export async function fetchWithTimeout(resource: any, options: any) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

export const handleTermsConditionsValidation = (payload: UserInfo) => {
  const isPublicDataset =
    payload?.activeClient?.domain === ClientDomainEnum.PUBLIC_DATASET_CATALOG;

  if (isPublicDataset && !payload.activeUser?.profile?.company) {
    dispatch(handleIncompleteProfile());
  }
};

/**
 * @method handleAccess
 * @description Used to handle an API authorization response
 * @param {Response} res
 * @returns {Response}
 */
export const handleAccess = (res: Response | any) => {
  if (res?.status === 401) {
    dispatch(handleUnauthorizedAccess());
    throw res;
  } else if (res?.status === 403) {
    getManagerInfo()
      .then((e) => {
        dispatch(push(ROUTES.ROOT_LOGGED.path));
      })
      .catch((e) => {
        dispatch(handleUnauthorizedAccess());
        throw res;
      });
  } else {
    return res;
  }
};

export const showIfError = async (
  res: Response,
  throwOnError: boolean = false,
  resultIfError: any = null
) => {
  if (typeof res === 'string') {
    toast(res, { type: toastTypes.error, icon: ToastIcon });
    return res;
  }
  const returnRes = res?.clone() || res;
  if (!res?.ok) {
    const msg = await getError(res);
    toast(msg, { type: toastTypes.error, toastId: msg, icon: ToastIcon });
    if (resultIfError) {
      return new Response(JSON.stringify(resultIfError), {
        ...res,
      });
    }
    if (throwOnError) {
      throw new Error(msg);
    }
  }
  return returnRes;
};

/**
 * @method handleError
 * @description Used to handle an API error response
 * @param {Response} res
 * @returns {Response}
 */
export const handleError = (res: Response | any) => {
  if (res?.status === 409) {
    throw res;
  } else {
    return res;
  }
};

export const getNameFilterQueryString = (name: string | undefined) => {
  return name
    ? `?${[name && `filter=name:ilike:${name}`].filter(Boolean).join('&')}`
    : '';
};

/**
 * @method getConnectionTestResourceByType
 * @description Used to get an API path by connection type
 * @param {DataPipelinesConnectionTypesEnum} type
 * @param _section
 * @returns {string}
 */
export const getConnectionTestResourceByType = (
  type: DataPipelinesConnectionTypesEnum,
  _section?: DataPipelinesSectionEnum | string
) => {
  switch (type) {
    case DataPipelinesConnectionTypesEnum.API:
      return API_BASE_URL + API_PATH_DATA_PIPELINE_CONNECTION_TEST_API;
    case DataPipelinesConnectionTypesEnum.FORMS:
      return API_BASE_URL + API_PATH_DATA_PIPELINE_FORMS;
    case DataPipelinesConnectionTypesEnum.JDBC:
      return API_BASE_URL + API_PATH_DATA_PIPELINE_CONNECTION_TEST_JDBC;
    case DataPipelinesConnectionTypesEnum.SERVICE:
      return API_BASE_URL + API_PATH_DATA_PIPELINE_CONNECTION_TEST;
    default:
      return API_BASE_URL + API_PATH_DATA_PIPELINE_CONNECTION_TEST;
  }
};

/**
 * @method getConnectionTestTypeBySection
 * @description Used to get the connection test type by form section.
 * @param {DataPipelinesSectionEnum} section
 * @returns {DataPipelinesConnectionTypesEnum}
 */
export const getConnectionTestTypeBySection = (
  section: DataPipelinesSectionEnum
): DataPipelinesConnectionTypesEnum => {
  if (SECTION_CONNECTION_TEST_API.some((item) => item === section)) {
    return DataPipelinesConnectionTypesEnum.API;
  }
  if (SECTION_CONNECTION_TEST_SERVICE.some((item) => item === section)) {
    return DataPipelinesConnectionTypesEnum.SERVICE;
  }
  return DataPipelinesConnectionTypesEnum.JDBC;
};

/**
 * @method connectionTestDataTransform
 * @description Used to transform an object to a proper connection test payload.
 * @param {DHIS2FormValues} data
 * @param {DataPipelinesSectionEnum} section
 * @returns any
 */
export const connectionTestDataTransform = (
  data: DHIS2FormValues,
  section: DataPipelinesSectionEnum
) => {
  if (SECTION_CONNECTION_TEST_API.some((item) => item === section)) {
    return payloadTransformConnectionTestAPI(data);
  } else if (SECTION_CONNECTION_TEST_JDBC.some((item) => item === section)) {
    return payloadTransformConnectionTestJDBC(data);
  } else if (section === DataPipelinesSectionEnum.API_SOURCE) {
    return payloadTransformConnectionTestAPISource(data);
  }

  return data;
};

/**
 * Convert object of key values pairs into a url query string
 * @param params Object of key values pairs
 * @returns url query string
 */
export const formatParams = (params: { [key: string]: any }) => {
  const paramsArr = [];
  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        if (![null, undefined, ''].includes(value)) {
          paramsArr.push(`${key}=${value}`);
        }
      }
    } else if (![null, undefined, ''].includes(params[key])) {
      paramsArr.push(`${key}=${params[key]}`);
    }
  }
  return paramsArr.join('&');
};

/**
 * Convert json to a valid request body, including JSON that has arrays of files as values
 * @param jsonData JSON data to be used in the request body
 * @param fileArrayFields Fields containing arrays of files which need to be handled differently
 * @returns
 */
export const makeBodyFromJson = (
  jsonData: any,
  fileArrayFields: { [s: string]: string } | undefined
) => {
  if (!fileArrayFields) {
    return JSON.stringify(jsonData);
  }
  const formData = new FormData();
  for (const key in jsonData) {
    if (key in fileArrayFields) {
      const targetKey = fileArrayFields[key];
      for (const file of jsonData[key]) {
        formData.append(targetKey, file);
      }
    } else {
      formData.append(key, jsonData[key]);
    }
  }
  return formData;
};

function getSchemaTableInfo() {
  return fetch(API_BASE_URL + API_PATH_DATABASE_DETAILS)
    .then(handleAccess)
    .then(showIfError)
    .then((res) => res.json());
}

/**
 * Build the required schema info for CodeEditors autocomplete
 */
export async function getAutoCompleteInfo() {
  try {
    const schemaInfo: SchemaTableInfo = await getSchemaTableInfo();
    const result: SchemaCodeEditor = {};
    for (const schema of schemaInfo?.objects || []) {
      for (const table of schema.objects || []) {
        const columnInfo = table.objects.map((column) => ({
          label: column.name,
        }));
        result[`${schema.name}.${table.name}`] = columnInfo;
      }
    }
    return result;
  } catch (err) {
    console.error('Error getting schema and table info: ', err);
    return {};
  }
}
