import {
  BreakPointEnum,
  BreakPoints,
  ComponentStatus,
  ComponentStatusEnum,
  DeviceSizeEnum,
  DeviceType,
  NavigationContextEnum,
  ThemeNameEnum,
} from '../interfaces/ui';
import * as tokens from '@baosystems/tokens/dist/baosystems';

export const NAVIGATION_CONTEXT: NavigationContextEnum =
  NavigationContextEnum.dataPipeline;

export const NAVIGATION_CONTEXT_LIST: Array<NavigationContextEnum> = [
  NavigationContextEnum.dataPipeline,
  NavigationContextEnum.publicDataPipeline,
];

export const THEMES_LIST: Array<ThemeNameEnum> = [
  ThemeNameEnum.bao,
  ThemeNameEnum.baosystems,
];

export const BREAKPOINTS: BreakPoints = {
  xs: BreakPointEnum.xs,
  sm: BreakPointEnum.sm,
  md: BreakPointEnum.md,
  lg: BreakPointEnum.lg,
  xl: BreakPointEnum.xl,
  xxl: BreakPointEnum.xxl,
  xxxl: BreakPointEnum.xxxl,
  SMALL_MOBILE: BreakPointEnum.xs,
  MOBILE: BreakPointEnum.sm,
  TABLET: BreakPointEnum.md,
  DESKTOP: BreakPointEnum.lg,
  WIDE: BreakPointEnum.xl,
  SUPER_WIDE: BreakPointEnum.xxl,
  ULTRA_WIDE: BreakPointEnum.xxxl,
};

export const DEVICE_TYPE: DeviceType = {
  SMALL_MOBILE: DeviceSizeEnum.xs,
  MOBILE: DeviceSizeEnum.sm,
  TABLET: DeviceSizeEnum.md,
  DESKTOP: DeviceSizeEnum.lg,
  WIDE: DeviceSizeEnum.xl,
  SUPER_WIDE: DeviceSizeEnum.xxl,
  ULTRA_WIDE: DeviceSizeEnum.xxxl,
};

export const COMPONENT_STATUS_LIST: Array<ComponentStatusEnum> = [
  ComponentStatusEnum.initial,
  ComponentStatusEnum.loading,
  ComponentStatusEnum.loaded,
  ComponentStatusEnum.connecting,
  ComponentStatusEnum.connected,
  ComponentStatusEnum.error,
  ComponentStatusEnum.indeterminate,
  ComponentStatusEnum.success,
];

export const COMPONENT_STATUS: ComponentStatus = {
  INITIAL: ComponentStatusEnum.initial,
  LOADING: ComponentStatusEnum.loading,
  LOADED: ComponentStatusEnum.loaded,
  CONNECTED: ComponentStatusEnum.connected,
  CONNECTING: ComponentStatusEnum.connecting,
  STOPPING: ComponentStatusEnum.stopping,
  STOPPED: ComponentStatusEnum.stopped,
  INDETERMINATE: ComponentStatusEnum.indeterminate,
  VALID: ComponentStatusEnum.valid,
  INVALID: ComponentStatusEnum.invalid,
  ERROR: ComponentStatusEnum.error,
  SUCCESS: ComponentStatusEnum.success,
};

export const DATE_FORMAT_NO_TIME = 'DD MMM YYYY';
export const DATE_FORMAT_COMPLETE: string = 'MMM D, YYYY h:mm:ss a';
export const DATE_FORMAT_FULL: string = 'MMMM Do YYYY, h:mm:ss a';
export const DATE_FORMAT_ONLY_TIME = 'h:mm:ss A';

export const MAX_WIDTH_DRAWER_CONTENT = 960;
export const MAX_WIDTH_CONTENT = 1032;
export const MIN_CONTENT_PADDING = 30;
export const HEADER_HEIGHT = 64;

export const scrollStyles = `
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: ${tokens.COLOR_NEUTRAL_GRAY_600};
}
::-webkit-scrollbar-thumb:hover {
  background: ${tokens.COLOR_NEUTRAL_GRAY_500};
}
`;
