import { SelectOptionComponent } from '../../interfaces/ui';
import i18next from 'i18next';
import { Dhis2SourceParamsEnum } from '@/shared/constants/dhis2';

const t = i18next.t.bind(i18next);

export enum DataPipelinePageVariation {
  DATASET = 'dataset',
  VIEWS = 'views',
  SCHEMA = 'schema',
  DESTINATION = 'destination',
  DATA_QUALITY_CHECK = 'dataQualityChecks',
  DATA_QUALITY_CHECK_GROUP = 'dataQualityCheckGroups'
}

export enum DataPipelinesSourcesEnum {
  DHIS2 = 'dhis2',
  POSTGRESQL = 'postgreSql',
  MYSQL = 'mySql',
  FUNCTION = 'function',
  PUBLIC_DATASET = 'publicDataset',
  CSV_UPLOAD = 'csvUpload',
  PARQUET_UPLOAD = 'parquetUpload',
  MSSQL = 'msSql',
  ORACLE = 'oracle',
  REDSHIFT = 'redshift',
  S3BUCKET = 's3',
  IHRIS = 'ihris',
  KOBO_TOOLBOX = 'koboToolbox',
  COMMCARE = 'commCare',
  ONA = 'ona',
  ODK = 'odk',
  VIEW = 'views',
  DATA_QUALITY_CHECK = 'dataQualityChecks',
  DATA_QUALITY_CHECK_GROUP = 'dataQualityCheckGroups',
  SCHEMA = 'schema',
  BHIMA = 'bhima',
}

export enum DataPipelinesDetailsTabEnum {
  SQL_STATEMENT = 'sql-statement',
  DETAILS = 'details',
  DATA_PREVIEW = 'preview',
  DATA_STRUCTURE = 'structure',
  DATA_LOG = 'logs',
}

export enum DataPipelinesStatusEnum {
  DISABLED = 'active',
  INACTVE = 'inactve',
  PAUSED = 'paused',
}

export enum DataPipelinesTableSchemaEnum {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum DataPipelinesTriggersEnum {
  DATA_DOWNLOAD = 'dataDownload',
  VIEW_EXPORT = 'viewExport',
  EDIT = 'edit',
  CODE_EDITOR = 'codeEditor',
  METADATA_DOWNLOAD = 'attachment',
  DATA_LOAD = 'dataLoad',
  DATA_SHARE = 'dataShare',
  DELETE = 'delete',
  DISCONNECT = 'disconnect',
  DUPLICATE = 'copy',
  ENABLE_SCHEDULE_REFRESH = 'enableScheduleRefresh',
  DISABLE_SCHEDULE_REFRESH = 'disableScheduleRefresh',
  PURGE_PIPELINE_FILES = 'fileRemoval',
}

export enum DataPipelinesConnectionTypesEnum {
  API = 'api',
  FORMS = 'forms',
  JDBC = 'jdbc',
  SERVICE = 'service',
}

export enum DataPipelinesDelimitersEnum {
  COMMA = 'COMMA',
  PIPE = 'PIPE',
  TAB = 'TAB',
}

export enum DataPipelinesRegions {
  DEFAULT = 'DEFAULT',
  US_EAST_1 = 'US_EAST_1',
  US_EAST_2 = 'US_EAST_2',
  US_WEST_1 = 'US_WEST_1',
  US_WEST_2 = 'US_WEST_2',
  AF_SOUTH_1 = 'AF_SOUTH_1',
  EU_CENTRAL_1 = 'EU_CENTRAL_1',
  EU_WEST_1 = 'EU_WEST_1',
  EU_WEST_2 = 'EU_WEST_2',
  EU_WEST_3 = 'EU_WEST_3',
  EU_SOUTH_1 = 'EU_SOUTH_1',
  EU_NORTH_1 = 'EU_NORTH_1',
}

export enum DataPipelinesShareTypesEnum {
  USER = 'USER',
  GROUP = 'USER_GROUP',
  PUBLIC = 'PUBLIC',
  OWNER = 'OWNER',
}

export enum DataPipelineDataPreviewType {
  PUBLIC_DATASET = 'publicDataset',
}

type MapperDataPipelinesSources = {
  [key: string]: DataPipelinesSourcesEnum;
};

export const MAPPER_DATA_PIPELINES_DHIS2_DATA_TYPES: any = {
  [Dhis2SourceParamsEnum.aggregateData]: 'aggregateData',
  [Dhis2SourceParamsEnum.program]: 'program',
  [Dhis2SourceParamsEnum.metadata]: 'metadata',
};

export const MAPPER_DATA_PIPELINES_SOURCES: MapperDataPipelinesSources = {
  DHIS2: DataPipelinesSourcesEnum.DHIS2,
  dhis2: DataPipelinesSourcesEnum.DHIS2,
  POSTGRESQL: DataPipelinesSourcesEnum.POSTGRESQL,
  postgresql: DataPipelinesSourcesEnum.POSTGRESQL,
  postgreSql: DataPipelinesSourcesEnum.POSTGRESQL,
  postgreSQL: DataPipelinesSourcesEnum.POSTGRESQL,
  REDSHIFT: DataPipelinesSourcesEnum.REDSHIFT,
  redshift: DataPipelinesSourcesEnum.REDSHIFT,
  RedShift: DataPipelinesSourcesEnum.REDSHIFT,
  Redshift: DataPipelinesSourcesEnum.REDSHIFT,
  redShift: DataPipelinesSourcesEnum.REDSHIFT,
  MYSQL: DataPipelinesSourcesEnum.MYSQL,
  mysql: DataPipelinesSourcesEnum.MYSQL,
  mySql: DataPipelinesSourcesEnum.MYSQL,
  mySQL: DataPipelinesSourcesEnum.MYSQL,
  MySQL: DataPipelinesSourcesEnum.MYSQL,
  FUNCTION: DataPipelinesSourcesEnum.FUNCTION,
  function: DataPipelinesSourcesEnum.FUNCTION,
  PUBLIC_DATASET: DataPipelinesSourcesEnum.PUBLIC_DATASET,
  public_dataset: DataPipelinesSourcesEnum.PUBLIC_DATASET,
  publicDataset: DataPipelinesSourcesEnum.PUBLIC_DATASET,
  publicdataset: DataPipelinesSourcesEnum.PUBLIC_DATASET,
  CSV_UPLOAD: DataPipelinesSourcesEnum.CSV_UPLOAD,
  csv_upload: DataPipelinesSourcesEnum.CSV_UPLOAD,
  csvUpload: DataPipelinesSourcesEnum.CSV_UPLOAD,
  csvupload: DataPipelinesSourcesEnum.CSV_UPLOAD,
  PARQUET_UPLOAD: DataPipelinesSourcesEnum.PARQUET_UPLOAD,
  parquet_upload: DataPipelinesSourcesEnum.PARQUET_UPLOAD,
  parquetUpload: DataPipelinesSourcesEnum.PARQUET_UPLOAD,
  parquetupload: DataPipelinesSourcesEnum.PARQUET_UPLOAD,
  ORACLE: DataPipelinesSourcesEnum.ORACLE,
  oracle: DataPipelinesSourcesEnum.ORACLE,
  MSSQL: DataPipelinesSourcesEnum.MSSQL,
  mssql: DataPipelinesSourcesEnum.MSSQL,
  msSql: DataPipelinesSourcesEnum.MSSQL,
  S3BUCKET: DataPipelinesSourcesEnum.S3BUCKET,
  s3bucket: DataPipelinesSourcesEnum.S3BUCKET,
  s3: DataPipelinesSourcesEnum.S3BUCKET,
  KOBO_TOOLBOX: DataPipelinesSourcesEnum.KOBO_TOOLBOX,
  kobo_toolbox: DataPipelinesSourcesEnum.KOBO_TOOLBOX,
  koboToolbox: DataPipelinesSourcesEnum.KOBO_TOOLBOX,
  kobotoolbox: DataPipelinesSourcesEnum.KOBO_TOOLBOX,
  commCare: DataPipelinesSourcesEnum.COMMCARE,
  commcare: DataPipelinesSourcesEnum.COMMCARE,
  COMMCARE: DataPipelinesSourcesEnum.COMMCARE,
  ona: DataPipelinesSourcesEnum.ONA,
  ONA: DataPipelinesSourcesEnum.ONA,
  odk: DataPipelinesSourcesEnum.ODK,
  ODK: DataPipelinesSourcesEnum.ODK,
  ihris: DataPipelinesSourcesEnum.IHRIS,
  IHRIS: DataPipelinesSourcesEnum.IHRIS,
  views: DataPipelinesSourcesEnum.VIEW,
  dataQualityChecks: DataPipelinesSourcesEnum.DATA_QUALITY_CHECK,
  dataQualityCheckGroups: DataPipelinesSourcesEnum.DATA_QUALITY_CHECK_GROUP,
  schema: DataPipelinesSourcesEnum.SCHEMA,
  bhima: DataPipelinesSourcesEnum.BHIMA,
  BHIMA: DataPipelinesSourcesEnum.BHIMA,
};

export const DATA_PIPELINE_SCHEMAS_OPTIONS: Array<SelectOptionComponent> = [
  {
    label: () => t('dataPipeline:schemas.ALL'),
    value: '',
  },
];

export const DESTINATIONS_TYPES_OPTIONS: Array<SelectOptionComponent> = [
  {
    label: () => t('dataPipeline:destinations.ALL'),
    value: '',
  },
  {
    label: () => t('dataPipeline:destinations.DHIS2'),
    value: 'DHIS2',
  },
];

export const DATA_PIPELINE_SOURCES_OPTIONS: Array<SelectOptionComponent> = [
  {
    label: () => t('dataPipeline:sources.ALL'),
    value: '',
  },
  {
    label: () => t('dataPipeline:sources.BHIMA'),
    value: 'BHIMA',
  },
  {
    label: () => t('dataPipeline:sources.COMMCARE'),
    value: 'COMMCARE',
  },
  {
    label: () => t('dataPipeline:sources.CSV_UPLOAD'),
    value: 'CSV_UPLOAD',
  },
  {
    label: () => t('dataPipeline:sources.DHIS2'),
    value: 'DHIS2',
  },
  {
    label: () => t('dataPipeline:sources.FUNCTION'),
    value: 'FUNCTION',
  },
  {
    label: () => t('dataPipeline:sources.IHRIS'),
    value: 'IHRIS',
  },
  {
    label: () => t('dataPipeline:sources.KOBO_TOOLBOX'),
    value: 'KOBO_TOOLBOX',
  },
  {
    label: () => t('dataPipeline:sources.MSSQL'),
    value: 'MSSQL',
  },
  {
    label: () => t('dataPipeline:sources.MYSQL'),
    value: 'MYSQL',
  },
  {
    label: () => t('dataPipeline:sources.ODK'),
    value: 'ODK',
  },
  {
    label: () => t('dataPipeline:sources.ONA'),
    value: 'ONA',
  },
  {
    label: () => t('dataPipeline:sources.ORACLE'),
    value: 'ORACLE',
  },
  {
    label: () => t('dataPipeline:sources.PARQUET_UPLOAD'),
    value: 'PARQUET_UPLOAD',
  },
  {
    label: () => t('dataPipeline:sources.POSTGRESQL'),
    value: 'POSTGRESQL',
  },
  {
    label: () => t('dataPipeline:sources.PUBLIC_DATASET'),
    value: 'PUBLIC_DATASET',
  },
  {
    label: () => t('dataPipeline:sources.REDSHIFT'),
    value: 'REDSHIFT',
  },
  {
    label: () => t('dataPipeline:sources.S3BUCKET'),
    value: 'S3BUCKET',
  },
];

export const DATA_PIPELINE_STATUS_OPTIONS: Array<SelectOptionComponent> = [
  {
    label: () => t('common:status.all'),
    value: '',
  },
  {
    label: () => t('common:status.connected'),
    value: 'false',
  },
  {
    label: () => t('common:status.disconnected'),
    value: 'true',
  },
];

export const DATA_PIPELINE_DELIMITER_OPTIONS = [
  {
    label: () => t('dataPipeline:delimiterTypes.COMMA'),
    value: DataPipelinesDelimitersEnum.COMMA,
  },
  {
    label: () => t('dataPipeline:delimiterTypes.PIPE'),
    value: DataPipelinesDelimitersEnum.PIPE,
  },
  {
    label: () => t('dataPipeline:delimiterTypes.TAB'),
    value: DataPipelinesDelimitersEnum.TAB,
  },
];

export const DATA_PIPELINE_REGION_OPTIONS = [
  {
    label: () => t('dataPipeline:regions.DEFAULT'),
    value: DataPipelinesRegions.DEFAULT,
  },
  {
    label: () => t('dataPipeline:regions.US_EAST_1'),
    value: DataPipelinesRegions.US_EAST_1,
  },
  {
    label: () => t('dataPipeline:regions.US_EAST_2'),
    value: DataPipelinesRegions.US_EAST_2,
  },
  {
    label: () => t('dataPipeline:regions.US_WEST_1'),
    value: DataPipelinesRegions.US_WEST_1,
  },
  {
    label: () => t('dataPipeline:regions.US_WEST_2'),
    value: DataPipelinesRegions.US_WEST_2,
  },
  {
    label: () => t('dataPipeline:regions.EU_SOUTH_1'),
    value: DataPipelinesRegions.EU_SOUTH_1,
  },
  {
    label: () => t('dataPipeline:regions.AF_SOUTH_1'),
    value: DataPipelinesRegions.AF_SOUTH_1,
  },
  {
    label: () => t('dataPipeline:regions.EU_CENTRAL_1'),
    value: DataPipelinesRegions.EU_CENTRAL_1,
  },
  {
    label: () => t('dataPipeline:regions.EU_WEST_1'),
    value: DataPipelinesRegions.EU_WEST_1,
  },
  {
    label: () => t('dataPipeline:regions.EU_WEST_2'),
    value: DataPipelinesRegions.EU_WEST_2,
  },
  {
    label: () => t('dataPipeline:regions.EU_WEST_3'),
    value: DataPipelinesRegions.EU_WEST_3,
  },
  {
    label: () => t('dataPipeline:regions.EU_NORTH_1'),
    value: DataPipelinesRegions.EU_NORTH_1,
  },
];
