import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { THEMES_LIST } from '../../constants';

export const themes = THEMES_LIST;

export default function useTheme(theme: string) {
  const [tokens, setTheme] = useState(false);
  const themeFromContext = useContext(ThemeContext);
  const targetTheme = theme || themeFromContext || {};
  const isValidTheme = themes.some(item => targetTheme.name === item);
  const themeName = isValidTheme ? targetTheme.name : THEMES_LIST[0];

  useEffect(() => {
    setTheme(require(`@baosystems/tokens/dist/${themeName}/index.js`));
  }, [themeName]);

  return {
    name: themeName,
    brand: themeName,
    tokens
  };
}
