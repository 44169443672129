// RESPONSE
export const STATUS_NOT_FOUND = 404;
export const STATUS_OK = 200;
export const STATUS_TEXT_NOT_FOUND = 'NOT_FOUND';
export const STATUS_TEXT_UNAUTHORIZED = 'UNAUTHORIZED';
export const STATUS_UNAUTHORIZED = 401;
export const API_TIMEOUT = 7000;

// PATHS: COMMON
export const APPS_MANAGER_URL = window.location.origin + "/app/manager/";
export const APPS_MANAGER_USER_URL = window.location.origin + "/app/manager/#users";
export const APPS_ANALYTICS_PLATFORM_URL = "/manager/";
export const API_BASE_URL = process.env.REACT_APP_API_URL || '';
export const API_PATH_SIGNUP = '/api/publicDatasetCatalog/signup';
export const API_PATH_LOGIN = '/login_check';
export const API_PATH_LOGOUT = '/session_logout';
export const API_PATH_MANAGER_INFO = '/api/activeUser';
export const API_PATH_PUBLIC_DATASETS = '/api/publicDatasets';
export const API_PATH_PUBLIC_DATASETS_ITEM = '/api/publicDatasets/:key';
export const API_PATH_SCHEMAS = '/api/schemas';
export const API_PATH_SCHEMAS_ITEM = '/api/schemas/:id';
export const API_PATH_DATABASE_DETAILS = '/api/databases';
export const API_PATH_DATABASE_QUERY = '/api/databases/query';
export const URL_PATH_ACCOUNT_RECOVERY = '/dhis-web-commons/security/recovery.action';
export const API_PATH_DATA_SHARING = '/api/sharing/search?query=:searchQuery'

// PATHS: USER

export const API_PATH_USERS = "/api/users/{{uid}}/password"
export const API_PATH_USER_UPDATE_PASSWORD = "/api/users/:id/password"
export const API_PATH_USER_PROFILE = "/api/publicDatasetCatalog/:id/profile"
export const API_PATH_INVITATION_VALIDATION = "/api/publicDatasetCatalog/:userId/invitation/:code"
export const API_PATH_INVITATION = "/api/publicDatasetCatalog/:userId/invitation"
export const API_PATH_USER_RESET_PASSWORD = "/api/publicDatasetCatalog/:username/reset"


// PATHS: DATA PIPELINE
export const API_PATH_DATA_PIPELINE_USER_LIST = '/api/users?analyticsPlatform=true';
export const API_PATH_DATA_PIPELINE_CONNECTION_TEST = '/api/dataPipelines/:source/:id?/connectionTest'; // S3
export const API_PATH_DATA_PIPELINE_CONNECTION_TEST_API = '/api/dataPipelines/:source/:id?/apiConnectionTest'; // DHIS2
export const API_PATH_DATA_PIPELINE_CONNECTION_TEST_JDBC = '/api/dataPipelines/:source/:id?/jdbcConnectionTest'; // DATABASE
export const API_PATH_DATA_PIPELINE = '/api/dataPipelines';
export const API_PATH_DATA_PIPELINE_SYSTEM_INFO = '/api/dataPipeline/system/info';
export const API_PATH_DATA_PIPELINE_TAGS = '/api/dataPipelines/tags?name=:tagName';
export const API_PATH_DATA_PIPELINE_ACCESS = '/api/dataPipelines/:source/:id/access';
export const API_PATH_DATA_PIPELINE_ANONYMOUS_ACCESS = '/api/dataPipelines/:source/:id/anonymousAccess';
export const API_PATH_DATA_PIPELINE_CATALOG = '/api/dataPipelines';
export const API_PATH_DATA_PIPELINE_CATALOG_ITEM = '/api/dataPipelines/:source/:id';
export const API_PATH_DATA_PIPELINE_CATALOG_ITEM_CREATE = '/api/dataPipelines/:source';
export const API_PATH_DATA_PIPELINE_CONFIG = '/api/connectionInfo';
export const API_PATH_DATA_PIPELINE_CONNECTION_STATUS = '/api/dataPipelines/:source/:id/dataPipelineConnectionTest';
export const API_PATH_DATA_PIPELINE_DHIS2 = '/api/dataPipelines/dhis2';
export const API_PATH_DATA_PIPELINE_FUNCTION = '/api/dataPipelines/function';
export const API_PATH_DATA_PIPELINE_LOGS = '/api/dataPipelineLogs';
export const API_PATH_DATA_PIPELINE_MYSQL = '/api/dataPipelines/mySql';
export const API_PATH_DATA_PIPELINE_POSTGRES = '/api/dataPipelines/postgreSql';
export const API_PATH_DATA_PIPELINE_PUBLIC_DATASETS = '/api/dataPipelines/publicDataset';
export const API_PATH_DATA_PIPELINE_PUBLIC_DATASETS_DATA_PREVIEW = '/api/dataPipelines/publicDataset/:id/dataPreview';
export const API_PATH_DATA_PIPELINE_PUBLIC_DATASETS_TABLE_SCHEMA = '/api/dataPipelines/publicDataset/:id/tableStructure';
export const API_PATH_DATA_PIPELINE_DATA_PREVIEW = '/api/dataPipelines/:source/:id/dataPreview';
export const API_PATH_DATA_PIPELINE_DATA_DOWNLOAD = '/api/dataPipelines/:source/:id/dataDownload';
export const API_PATH_DATA_PIPELINE_DATA_DOWNLOAD_OBJECT = '/api/dataPipelines/:source/:id/dataDownload/:objectId';
export const API_PATH_DATA_PIPELINE_TABLE_SCHEMA = '/api/dataPipelines/:source/:id/tableStructure';
export const API_PATH_VIEWS_TABLE_SCHEMA = '/api/:source/:id/structure';
export const API_PATH_DATA_PIPELINE_TRIGGER = '/api/dataPipelines/:source/:id/:trigger';
export const API_PATH_DATA_PIPELINE_FILE_UPLOAD= '/api/dataPipelines/:source/fileUpload'
export const API_PATH_DATA_PIPELINE_FILE_RE_UPLOAD= '/api/dataPipelines/:source/:id/fileUpload';
export const API_PATH_DATA_PIPELINE_FILE_REMOVE = '/api/dataPipelines/csvUpload/:id/fileRemoval'
export const API_PATH_DHIS2_PROGRAMS = '/api/dataPipelines/dhis2/programs';
export const API_PATH_DHIS2_DE_GROUPS = '/api/dataPipelines/dhis2/dataElementGroups?search=:search';
export const API_PATH_DHIS2_OUS = '/api/dataPipelines/dhis2/orgUnits?search=:search';
export const API_PATH_DATA_PIPELINE_DHIS2_PROGRAMS = '/api/dataPipelines/dhis2/:id/programs';
export const API_PATH_DATA_PIPELINES_DHIS_DE_GROUPS = '/api/dataPipelines/dhis2/:id/dataElementGroups?search=:search'
export const API_PATH_DATA_PIPELINES_DHIS_OUS = '/api/dataPipelines/dhis2/:id/orgUnits?search=:search'

export const API_PATH_DATA_PIPELINE_IHRIS_FORMS = '/api/dataPipelines/ihris/forms';
export const API_PATH_DATA_PIPELINE_IHRIS_FORMS_BY_ID = '/api/dataPipelines/ihris/:id/forms';
export const API_PATH_IHRIS_REFERENCE_TABLES = '/api/dataPipelines/ihris/references';
export const API_PATH_DATA_PIPELINE_IHRIS_REFERENCE_TABLES = '/api/dataPipelines/ihris/:id/references';

export const API_PATH_DATA_PIPELINE_KOBO_TOOLBOX_SURVEYS = '/api/dataPipelines/koboToolbox/surveys';
export const API_PATH_DATA_PIPELINE_KOBO_TOOLBOX_SURVEYS_BY_ID = '/api/dataPipelines/koboToolbox/:id/surveys';

export const API_PATH_DATA_PIPELINE_COMM_CARE_DOMAINS = '/api/dataPipelines/commCare/domains';
export const API_PATH_DATA_PIPELINE_COMM_CARE_APPLICATIONS = '/api/dataPipelines/commCare/domains/:domainId/applications';
export const API_PATH_DATA_PIPELINE_COMM_CARE_FORMS = '/api/dataPipelines/commCare/domains/:domainId/applications/:applicationId/forms';
export const API_PATH_DATA_PIPELINE_DOMAINS_BY_ID = '/api/dataPipelines/:source/:id/domains';
export const API_PATH_DATA_PIPELINE_APPLICATIONS = '/api/dataPipelines/:source/:id/domains/:domainId/applications';
export const API_PATH_DATA_PIPELINE_FORMS = '/api/dataPipelines/:source/forms';
export const API_PATH_DATA_PIPELINE_FORMS_BY_ID = '/api/dataPipelines/:source/:id/forms';

export const API_PATH_DATA_PIPELINE_PROJECTS = '/api/dataPipelines/:source/projects';
export const API_PATH_DATA_PIPELINE_PROJECTS_BY_ID = '/api/dataPipelines/:source/:id/projects';
export const API_PATH_DATA_PIPELINE_PROJECT_FORMS = '/api/dataPipelines/:source/projects/:projectId/forms';
export const API_PATH_DATA_PIPELINE_PROJECT_FORMS_BY_ID = '/api/dataPipelines/:source/:id/projects/:projectId/forms';


// PATHS: SCHEMAS
export const API_PATH_DATA_PIPELINE_SCHEMAS = '/api/schemas';
export const API_PATH_DATA_PIPELINE_SCHEMA_ITEM = '/api/schemas/:id';
export const API_PATH_DATA_PIPELINE_SCHEMA_ITEM_TABLES = '/api/schemas/:id/tables';
export const API_PATH_DATA_PIPELINE_SCHEMA_ITEM_VIEWS = '/api/schemas/:id/views';
export const API_PATH_DATA_PIPELINE_SCHEMA_ITEM_TABLE_NAMES = '/api/schemas/:id/tableNames';
export const API_PATH_DATA_PIPELINE_SCHEMA_TABLE_COLUMNS = '/api/schemas/:schemaId/tables/:tableName'

// PATHS: DATA VIEWS
export const API_PATH_DATA_PIPELINE_VIEWS = '/api/views';
export const API_PATH_DATA_PIPELINE_VIEWS_ITEM = '/api/views/:id';
export const API_PATH_VIEWS_DATA_PREVIEW = '/api/views/:id/dataPreview';
export const API_PATH_VIEWS_ACCESS = '/api/views/:id/access';
export const API_PATH_VIEWS_ANONYMOUS_ACCESS = '/api/views/:id/anonymousAccess'
export const API_PATH_VIEWS_DATA_DOWNLOAD = '/api/views/:id/download';
export const API_PATH_VIEWS_TRIGGER = '/api/views/:id/:trigger';
export const API_PATH_VIEWS_EXPORT = '/api/views/:id/unload';

// PATHS: DATA QUALITY CHECKS
export const API_PATH_DATA_PIPELINE_DATA_QUALITY_CHECKS = '/api/dataQualityChecks';
export const API_PATH_DATA_PIPELINE_DATA_QUALITY_CHECKS_ITEM = '/api/dataQualityChecks/:id';
export const API_PATH_DATA_QUALITY_CHECKS_ACCESS = '/api/dataQualityChecks/:id/access';
export const API_PATH_DATA_QUALITY_CHECKS_ANONYMOUS_ACCESS = '/api/dataQualityChecks/:id/anonymousAccess';

// PATHS: DATA QUALITY CHECK GROUPS
export const API_PATH_DATA_QUALITY_CHECK_GROUPS = '/api/dataQualityCheckGroups';
export const API_PATH_DATA_QUALITY_CHECK_GROUPS_ITEM = '/api/dataQualityCheckGroups/:id';
export const API_PATH_DATA_QUALITY_CHECK_GROUPS_ACCESS = '/api/dataQualityCheckGroups/access';
export const API_PATH_DATA_QUALITY_CHECK_GROUPS_ANONYMOUS_ACCESS = '/api/dataQualityCheckGroups/:id/anonymousAccess';

// PATHS: DESTINATIONS
export const API_PATH_DESTINATIONS = '/api/destinations';
export const API_PATH_DESTINATIONS_CATALOG_ITEM = '/api/destinations/:source/:id';
export const API_PATH_DESTINATIONS_CATALOG_ITEM_CREATE = '/api/destinations/:source';
export const API_PATH_DESTINATIONS_TRIGGER = '/api/destinations/:source/:id/:trigger';
export const API_PATH_DESTINATIONS_CONNECTION_TEST_API = '/api/destinations/:source/:id?/connectionTest';
export const API_PATH_DESTINATION_LOGS = '/api/destinationLogs';
export const API_PATH_DESTINATION_ACCESS = '/api/destinations/:source/:id/access';

// PATHS: VARIABLES
export const API_PATH_VARIABLES = '/api/v2/variables';
export const API_PATH_VARIABLES_ITEM = '/api/v2/variables/:id';

// PATHS: FIREWALL RULES
export const API_PATH_FIREWALL_RULES = '/api/firewallRules';
export const API_PATH_FIREWALL_RULE_ITEM = '/api/firewallRules/:id';

// PATHS: SETTINGS
export const API_PATH_SETTINGS = '/api/settings';

// QUERY CACHES IDs
export const QUERY_CACHE_USER_INVITATION = 'user-invitation';
export const QUERY_CACHE_USER_ROLES = 'user-roles';
export const QUERY_CACHE_IS_PUBLIC_DATA_PIPELINE = 'isPublicDataPipeline';
export const QUERY_CACHE_APPLICATION_CONTEXT = 'applicationContext';
export const QUERY_CACHE_MANAGER_INFO = 'manager-info';
export const QUERY_CACHE_CONNECTION_INFO = 'connection-info';
export const QUERY_CACHE_CATALOG = 'catalog';
export const QUERY_CACHE_CATALOG_ITEM = 'catalog-item';
export const QUERY_CACHE_CATALOG_ITEM_SHARE_DATA = 'catalog-item-share-data';
export const QUERY_CACHE_VIEWS_ITEM_SHARE_DATA = 'views-item-share-data';
export const QUERY_CACHE_CATALOG_ITEM_SQL_TRANSFORMATIONS = 'catalog-item-sql-transformations';
export const QUERY_CACHE_CATALOG_ITEM_FORM = 'catalog-item-form';
export const QUERY_CACHE_DATA_DOWNLOAD = 'data-download';
export const QUERY_CACHE_DATA_PIPELINE_SYSTEM_INFO = 'data-pipeline-system-info';
export const QUERY_CACHE_DATA_PIPELINE_TAGS = 'data-pipeline-tags';
export const QUERY_CACHE_PAGE_DETAILS = 'page-details';
export const QUERY_CACHE_TAB_PREVIEW_TABLE = 'tab-preview-table';
export const QUERY_CACHE_TAB_DETAILS = 'tab-details';
export const QUERY_CACHE_MENU_DETAILS = 'menu-details';
export const QUERY_CACHE_TAB_LOGS = 'tab-logs';
export const QUERY_CACHE_TAB_LOGS_TASK = 'tab-logs-task';
export const QUERY_CACHE_TAB_TABLE_SCHEMA = 'tab-table-schema';
export const QUERY_CACHE_PUBLIC_DATASETS = 'public-datasets';
export const QUERY_CACHE_USER_PUBLIC_DATASETS = 'user-public-datasets';
export const QUERY_CACHE_DATA_PIPELINE_USER_LIST = 'data-pipeline-user-list';
export const QUERY_CACHE_VIEWS = 'views';
export const QUERY_CACHE_DATA_QUALITY_CHECKS = 'data-quality-checks'
export const QUERY_CACHE_DATA_QUALITY_CHECK_GROUPS = 'data-quality-check-groups'
export const QUERY_CACHE_SCHEMAS = 'schemas';
export const QUERY_CACHE_DESTINATIONS = 'destinations';
export const QUERY_CACHE_DESTINATIONS_ITEM = 'destination-item';
export const QUERY_CACHE_SCHEMAS_TABLE_NAMES = 'schemas-table-names';
export const QUERY_CACHE_VIEWS_ITEM_FORM = 'catalog-views-form';
export const QUERY_CACHE_SCHEMA_ITEM_FORM = 'catalog-schema-form';
export const QUERY_CACHE_VARIABLES = 'variables';
export const QUERY_CACHE_RULES = 'rules';
export const QUERY_CACHE_SETTINGS = 'settings';

const fiveMinutesInMilliseconds = 1000 * 60 * 5 * 24;
export const SHARED_QUERY_CACHE_CATALOG_CONFIG = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: fiveMinutesInMilliseconds,
};




