import { THEMES } from '@baosystems/nebula';
import { RouteProps } from 'react-router';
import * as React from 'react';

export enum NavigationContextEnum {
  dataPipeline = 'dataPipeline',
  publicDataPipeline = 'publicDataPipeline',
}

export enum ThemeNameEnum {
  bao = THEMES.BAO,
  baosystems = THEMES.BAO_SYSTEMS,
}

export interface BreakPoints {
  xs: BreakPointEnum.xs;
  sm: BreakPointEnum.sm;
  md: BreakPointEnum.md;
  lg: BreakPointEnum.lg;
  xl: BreakPointEnum.xl;
  xxl: BreakPointEnum.xxl;
  xxxl: BreakPointEnum.xxxl;
  SMALL_MOBILE: BreakPointEnum.xs;
  MOBILE: BreakPointEnum.sm;
  TABLET: BreakPointEnum.md;
  DESKTOP: BreakPointEnum.lg;
  WIDE: BreakPointEnum.xl;
  SUPER_WIDE: BreakPointEnum.xxl;
  ULTRA_WIDE: BreakPointEnum.xxxl;
  [name: string]: number;
}

export enum BreakPointEnum {
  xs = 0,
  sm = 576,
  md = 1024,
  lg = 1200,
  xl = 1440,
  xxl = 1600,
  xxxl = 1920,
}

export enum DeviceSizeEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export interface DeviceType {
  SMALL_MOBILE: DeviceSizeEnum.xs;
  MOBILE: DeviceSizeEnum.sm;
  TABLET: DeviceSizeEnum.md;
  DESKTOP: DeviceSizeEnum.lg;
  WIDE: DeviceSizeEnum.xl;
  SUPER_WIDE: DeviceSizeEnum.xxl;
  ULTRA_WIDE: DeviceSizeEnum.xxxl;
}

export enum ComponentStatusEnum {
  initial = 'initial',
  loading = 'loading',
  loaded = 'loaded',
  connecting = 'connecting',
  connected = 'connected',
  stopping = 'stopping',
  stopped = 'stopped',
  error = 'error',
  valid = 'valid',
  invalid = 'invalid',
  indeterminate = 'indeterminate',
  success = 'SUCCESS',
}

export interface ComponentStatus {
  INITIAL: ComponentStatusEnum;
  LOADING: ComponentStatusEnum;
  LOADED: ComponentStatusEnum;
  CONNECTING: ComponentStatusEnum;
  CONNECTED: ComponentStatusEnum;
  STOPPING: ComponentStatusEnum;
  STOPPED: ComponentStatusEnum;
  ERROR: ComponentStatusEnum;
  VALID: ComponentStatusEnum;
  INVALID: ComponentStatusEnum;
  INDETERMINATE: ComponentStatusEnum;
  SUCCESS: ComponentStatusEnum;
}

export interface LayoutDrawerConfig {
  routes: Array<RouteProps | any>;
  width?: string;
  callbackURL: string;
}

export interface SelectOptionComponent {
  label: any;
  value: any;
}

export interface TableColumn {
  title: string;
  dataIndex?: string;
  key?: string;
  render?: React.JSXElementConstructor<any> | any;
  width?: string;
  minWidth?: string;
  ellipsis?: boolean;
  fixed?: any;
}

export interface NotificationState {
  type: 'message' | null;
  props?: { children?: any; [key: string]: any };
}
