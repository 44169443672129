import { FrequencyEnum } from '@/shared/constants/time';
import i18next from 'i18next';
const t = i18next.t.bind(i18next);

export enum DataPipelinesSectionEnum {
  SETTINGS = 'settings',
  DATABASE = 'database',
  SOURCE = 'source',
  WEBAPI = 'webApi',
  API_SOURCE = 'apiSource',
  API_SOURCE_KOBO = 'apiSourceKobo',
  API_SOURCE_FORMS = 'apiSourceForms',
  APP_SETTINGS = 'appSettings',
  DATA_WAREHOUSE_TARGET = 'dataWarehouseTarget',
  QUERY = 'query',
  DATATYPES = 'dataTypes',
  SQL_STATEMENT = 'sqlStatement',
}

export const SECTION_CONNECTION_TEST_API: Array<DataPipelinesSectionEnum> = [
  DataPipelinesSectionEnum.WEBAPI,
];
export const SECTION_CONNECTION_TEST_JDBC: Array<DataPipelinesSectionEnum> = [
  DataPipelinesSectionEnum.DATABASE,
];
export const SECTION_CONNECTION_TEST_SERVICE: Array<DataPipelinesSectionEnum> = [
  DataPipelinesSectionEnum.API_SOURCE,
];

export const FREQUENCY_OPTIONS = [
  {
    label: () => t('common:time.frequency.NEVER'),
    value: FrequencyEnum.NEVER,
  },
  {
    label: () =>
      t('common:time.frequency.EVERY_15_MINUTES', {
        count: 15,
      }),
    value: FrequencyEnum.EVERY_15_MINUTES,
  },
  {
    label: () => t('common:time.frequency.EVERY_30_MINUTES'),
    value: FrequencyEnum.EVERY_30_MINUTES,
  },
  {
    label: () => t('common:time.frequency.EVERY_DAY_0AM'),
    value: FrequencyEnum.EVERY_DAY_0AM,
  },
  {
    label: () => t('common:time.frequency.EVERY_DAY_2AM'),
    value: FrequencyEnum.EVERY_DAY_2AM,
  },
  {
    label: () => t('common:time.frequency.EVERY_WEEKDAY'),
    value: FrequencyEnum.EVERY_WEEKDAY,
  },
  {
    label: () => t('common:time.frequency.EVERY_NIGHT_RANDOM'),
    value: FrequencyEnum.EVERY_NIGHT_RANDOM,
  },
  {
    label: () => t('common:time.frequency.EVERY_SATURDAY'),
    value: FrequencyEnum.EVERY_SATURDAY,
  },
  {
    label: () => t('common:time.frequency.EVERY_SATURDAY_NIGHT_RANDOM'),
    value: FrequencyEnum.EVERY_SATURDAY_NIGHT_RANDOM,
  },
  {
    label: () => t('common:time.frequency.EVERY_MONTH'),
    value: FrequencyEnum.EVERY_MONTH,
  },
  {
    label: () => t('common:time.frequency.EVERY_MONTH_RANDOM'),
    value: FrequencyEnum.EVERY_MONTH_RANDOM,
  },
  {
    label: () => t('common:time.frequency.EVERY_3_HOURS'),
    value: FrequencyEnum.EVERY_3_HOURS,
  },
  {
    label: () => t('common:time.frequency.EVERY_HOUR'),
    value: FrequencyEnum.EVERY_HOUR,
  },
];

export const SECTION_CONNECTION_TEST = [
  ...SECTION_CONNECTION_TEST_API,
  ...SECTION_CONNECTION_TEST_JDBC,
  ...SECTION_CONNECTION_TEST_SERVICE,
];
