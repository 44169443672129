export default function getConfig(): any {
  if (process.env.REACT_APP_DEV || process.env.NODE_ENV === 'development') {
    return {
      store: require('./configure-store.dev').default,
      history: require('./configure-store.dev').history
    };
  }
  return {
    store: require('./configure-store.prod').default,
    history: require('./configure-store.prod').history
  };
}
