import { THEMES } from '../../constants';
import { AppPropertiesEnum } from '@/shared/hooks/app';

const initialState = {
  [AppPropertiesEnum.theme]: {
    name: THEMES.BAO_SYSTEMS,
    brand: THEMES.BAO_SYSTEMS,
  },
  [AppPropertiesEnum.applicationContext]: null,
  [AppPropertiesEnum.isPublicDataPipeline]: null,
  api: {
    baseURL: null,
    username: null,
    password: null,
  },
};

const mockedInitialState = {
  ...initialState,
  api: {},
};

export default process.env.REACT_APP_USE_MOCK !== 'true'
  ? initialState
  : mockedInitialState;
