import { UserRolesEnum, UserRolesTypes } from '@/shared/interfaces/user';

/**
 * @function shouldDisplayUserRoles
 *
 * @description Check if a role list has intersection with another one
 * @param {UserRolesTypes | []} displayRoles
 * @param {UserRolesTypes | []} userRoles
 * @return {boolean}
 */
function shouldDisplayUserRoles(
  displayRoles: UserRolesTypes | [] = [],
  userRoles: UserRolesTypes | [] = []
): boolean {
  const displayRolesList: Array<keyof typeof UserRolesEnum> = Array.isArray(
    displayRoles
  )
    ? displayRoles
    : [displayRoles];
  const userRolesList: Array<keyof typeof UserRolesEnum> = Array.isArray(
    userRoles
  )
    ? userRoles
    : [userRoles];


  return displayRolesList.some((role) => userRolesList.includes(role));
}

export { shouldDisplayUserRoles };
