import {ClientDomainEnum} from "@/shared/interfaces/user";

export function isEnterpriseInstance():boolean{
    let domain:ClientDomainEnum = getEnterpriseDomain();
    return domain===ClientDomainEnum.PEPFAR_DATA_MART||domain===ClientDomainEnum.PUBLIC_DATASET_CATALOG;
}

export function isPepfar():boolean{
    return getEnterpriseDomain()===ClientDomainEnum.PEPFAR_DATA_MART;
}

export function getEnterpriseDomain():ClientDomainEnum{
    let domain:string = process.env.REACT_APP_CLIENT_DOMAIN as string;
    if (Object.keys(ClientDomainEnum).includes(domain as ClientDomainEnum)) return domain as ClientDomainEnum;
    else return ClientDomainEnum.REGULAR;
}

function isDev(){
    return process.env.NODE_ENV==='development';
}

function getCssPath():string|null{
    if (isPepfar()&&isDev()) return '/pepfar/pepfar.css';
    if (isPepfar()&&!isDev()) return '/manager/pepfar/pepfar.css';
    return null;
}

export function getPepfarLogo():string{
    if (isDev()) return '/pepfar/pepfar-1200-transparent.png';
    else return '/manager/pepfar/pepfar-1200-transparent.png';
}

export function importStyles(){
    let cssPath:string|null = null;
    cssPath = getCssPath();
    if (!cssPath) return;
    var link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", cssPath);
    document.head.appendChild(link);
}