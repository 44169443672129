import { ClientDomainEnum, UserRoles, UserRolesEnum } from '../interfaces/user';
import i18next from 'i18next';

const t = i18next.t.bind(i18next);

export const USER_ROLES: UserRoles = {
  ROLE_MANAGE_INSTANCE: UserRolesEnum.ROLE_MANAGE_INSTANCE,
  ROLE_MANAGE_ROUTE: UserRolesEnum.ROLE_MANAGE_ROUTE,
  ROLE_SEE_INSTANCE: UserRolesEnum.ROLE_MANAGE_INSTANCE,
  ROLE_MANAGE_DATA_PIPELINE: UserRolesEnum.ROLE_MANAGE_DATA_PIPELINE,
};

export const DOMAIN_PATH_MAPPER: { [key: string]: ClientDomainEnum } = {
  // REGULAR
  analytics: ClientDomainEnum.REGULAR,
  authentication: ClientDomainEnum.REGULAR,
  'analytics-plaftorm': ClientDomainEnum.REGULAR,
  // PUBLIC DATASET CATALOG
  'open-data': ClientDomainEnum.PUBLIC_DATASET_CATALOG,
};

export enum UserAccountSectorEnum {
  AGRICULTURE_FOOD_SUPPLY = 'Agriculture & food supply',
  ECONOMIC_DEVELOPMENT = 'Economic development',
  EDUCATION = 'Education',
  HEALTH = 'Health',
  NUTRITION = 'Nutrition',
  WASH = 'WASH',
  OTHER = 'Other',
}

export const ACCOUNT_SECTOR_OPTIONS = [
  {
    label: () => t('myAccount:components.sector.agricultureFoodSecurity'),
    value: UserAccountSectorEnum.AGRICULTURE_FOOD_SUPPLY,
  },
  {
    label: () => t('myAccount:components.sector.economicDevelopment'),
    value: UserAccountSectorEnum.ECONOMIC_DEVELOPMENT,
  },
  {
    label: () => t('myAccount:components.sector.education'),
    value: UserAccountSectorEnum.EDUCATION,
  },
  {
    label: () => t('myAccount:components.sector.health'),
    value: UserAccountSectorEnum.HEALTH,
  },
  {
    label: () => t('myAccount:components.sector.nutrition'),
    value: UserAccountSectorEnum.NUTRITION,
  },
  {
    label: () => t('myAccount:components.sector.wash'),
    value: UserAccountSectorEnum.WASH,
  },
  {
    label: () => t('myAccount:components.sector.other'),
    value: UserAccountSectorEnum.OTHER,
  },
];

export const USER_ROLES_LIST: Array<string> = Object.keys(USER_ROLES);
