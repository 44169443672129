import { useDispatch, useSelector } from 'react-redux';
import { setProperty } from '@/shared/state/properties/actions';
import { baosystems } from '@baosystems/tokens';
import { NavigationContextEnum, ThemeNameEnum } from '@/shared/interfaces/ui';
import * as localforage from 'localforage';
import { STORAGE_NAME } from '@/shared/constants';
import { PreferredLoginEnum } from '@/shared/interfaces/user';

export enum AppPropertiesEnum {
  isPublicDataPipeline = 'isPublicDataPipeline',
  applicationContext = 'applicationContext',
  theme = 'theme',
  user = 'user',
  'user-roles' = 'user-roles',
  forwardTo = 'forwardTo',
}

export interface AppProperties {
  applicationContext: NavigationContextEnum;
  isPublicDataPipeline: boolean;
  user: any;
  theme: {
    name: ThemeNameEnum;
    tokens: typeof baosystems;
  };
  'user-roles': string[];
  forwardTo: PreferredLoginEnum;
}

/**
 * @description App properties management by Redux and Storage store.
 *
 * @return {{getProperty: (key: (keyof AppPropertiesEnum | string), source?: ("redux" | "storage")) => Promise<any>, setProperty: (key: (keyof AppPropertiesEnum | string | AppPropertiesEnum), value: any) => Promise<void>, properties: AppProperties}}
 */
const useAppProperties = () => {
  const dispatch = useDispatch();
  const { store } = useStorage();
  const { properties } = useSelector(({ properties }: any) => ({ properties }));

  const getAppProperty = async (
    key: keyof typeof AppPropertiesEnum | string,
    source?: 'redux' | 'storage'
  ) => {
    return source === 'redux'
      ? properties[key]
      : properties[key] ||
          (await store.getItem(key).then((value) => {
            dispatch(setProperty({ [key]: value }));
            return value;
          }));
  };

  const setAppProperty = async (
    key: keyof typeof AppPropertiesEnum | string | AppPropertiesEnum,
    value: any
  ) => {
    await store.setItem(key, value);
    dispatch(setProperty({ [key]: value }));
  };

  return {
    properties: Object.freeze(properties || {}) as AppProperties,
    getProperty: getAppProperty,
    setProperty: setAppProperty,
  };
};

interface AppStorageConfig {
  name?: string;
  storeName?: string;
}

/**
 * @description Asynchronous data storage management hook
 *
 * @param {AppStorageConfig} config
 * @return {{storage: LocalForage, store: LocalForage}}
 */
const useStorage = (config?: AppStorageConfig) => {
  const store = localforage.createInstance({
    name: config?.name || STORAGE_NAME,
    storeName: config?.storeName || 'settings',
    driver: [localforage.LOCALSTORAGE, localforage.INDEXEDDB],
  });

  return {
    storage: localforage,
    store,
  };
};
const getStorage = useStorage;

export { useAppProperties, useStorage, getStorage };
