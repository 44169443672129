import {getPepfarLogo} from "@/shared/services/domainProvider.service";

const styles = {
    logo: {
        height: 46,
        marginLeft: 2,
        marginRight: 2
    },
    title: {
        color: 'white',
        fontSize: 20,
        marginLeft: 14
    },
    pepfar: {
        fontWeight: 500
    }
}

export function PepfarLogo(){
    return <div>
        <img src={getPepfarLogo()} style={styles.logo} alt={'Pepfar logo'}/>
        <span id="pepfar-logo" style={styles.title}><span style={styles.pepfar}>PEPFAR</span> Data Mart</span>
    </div>
}