import { Col, Row } from '@baosystems/nebula';
import { useResponsive } from 'react-hooks-responsive';
import {
  BREAKPOINTS,
  DEVICE_TYPE,
  MIN_CONTENT_PADDING,
} from '@/shared/constants';
import styled, { css } from 'styled-components';

const ContentContainer = styled(Row)(
  ({ topPadding, bottomPadding }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${topPadding}px;
    padding-bottom: ${bottomPadding}px;
  `
);

const LayoutContentWrapper = ({
  children,
  fluid,
  padding,
  unsetMinHeight,
  ...props
}: {
  children: any;
  fluid?: boolean;
  unsetMinHeight?: boolean;
  padding?: {
    top?: number;
    bottom?: number;
  };
  [key: string]: any;
}) => {
  const { screenIsAtLeast } = useResponsive(BREAKPOINTS);
  const isWide = screenIsAtLeast(DEVICE_TYPE.WIDE);
  return (
    <ContentContainer
      topPadding={padding?.top}
      bottomPadding={padding?.bottom}
      style={{
        width: '100%',
        minHeight: unsetMinHeight ? 'unset' : '40px',
      }}
      {...props}
    >
      <Col
        span={!fluid && isWide ? 22 : 24}
        offset={!fluid && isWide ? 1 : 0}
        style={{
          maxWidth: '1700px',
          minWidth: '800px',
          width: '100%',
          padding: `0 ${!fluid && isWide ? 0 : MIN_CONTENT_PADDING}px`,
          transition: 'all 600ms ease-in-out',
        }}
      >
        {children}
      </Col>
    </ContentContainer>
  );
};

export default LayoutContentWrapper;
