import { COMPONENT_STATUS } from '../../constants';
import { INFO } from '../../stubs';

const initialState = {
  logged: true,
  status: COMPONENT_STATUS.INITIAL,
  data: {}
};

export const mockedInitialState = {
  logged: true,
  status: COMPONENT_STATUS.LOADED,
  data: INFO
};

export default process.env.REACT_APP_USE_MOCK !== 'true'
  ? initialState
  : mockedInitialState;
